// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiUrl: 'https://intranet2.brzinsurance.com/wp-json',
  // channelSlug: 'private-dev-intranet-',
  // pusherId: '4dd329861a1da30f0371',
  // productionMode: false,
  apiUrl: 'https://intranet.brzinsurance.com/wp-json',
  channelSlug: 'private-live-intranet-',
  pusherId: 'bb93f301005b0b16e53a',
  productionMode: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.