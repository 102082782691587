import { BlipComponent } from './../blip.component';
import { BlipServiceService } from './../blip-service.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewCollaboratorDealComponent } from '../../deals/new-collaborator-deal/new-collaborator-deal.component';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollaboratorsService } from '../../collaborators.service';
import { AgentsBlip } from '../../model/interfaces';
import { NewCollaboratorsBlipComponent } from '../new-collaborators-blip/new-collaborators-blip.component';
import { RemoveCollaboratorComponent } from '../remove-collaborator/remove-collaborator.component';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

export interface PeriodicElement {
  status: boolean;
  nome: string;
  email: string;
  acting: string;
  idiomas: Array<string>;
  dealsToday: number;
  totalDeals: number;
  menu: object;
}

@Component({
  selector: 'app-claim-blip',
  templateUrl: './claim-blip.component.html',
  styleUrls: ['./claim-blip.component.scss'],
})
export class ClaimBlipComponent implements OnInit {
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  dataSource: AgentsBlip[];
  displayedColumns: string[] = [
    'sequence',
    'distribuir',
    'nome',
    'email',
    'maxTickets',
    'openedTickets',
    'waitingTickets',
    'totalTickets',
    'closedTickets',
    'idiomas',
    'id',
  ];
  waitingTickets: number = 0;

  simultaneousTicket: number = 10;
  form: FormGroup;
  emptyData: boolean = false;
  private ticketChangeSubjects: { [key: string]: Subject<any> } = {};
  idSimultaneousTicket:string;
  constructor(
    public dialog: MatDialog,
    private BlipServiceService: BlipServiceService,
    private formBuilder: FormBuilder,
    private BlipComponent: BlipComponent
    
  ) {}
  ngOnInit(): void {
    const storedValue = window.localStorage.getItem('simultaneousTicketClaim');
    this.simultaneousTicket = storedValue ? +storedValue : 10;
    this.getQueue();
    setInterval(()=>{

      this.getQueue();
      this.returnWaitingTickets();
    },60000 )

    this.form = this.formBuilder.group({
      simultaneous: [10, Validators.required],
    });
    this.returnWaitingTickets();
    this.simultaneous();
  }

  simultaneous(){
    this.BlipServiceService.returnSimultaneousTicket().subscribe((data)=>{
     let dropdownNumber = data.filter(item => item.nome == "claim");
     this.form.get('simultaneous').setValue(dropdownNumber[0].maxTickets)
    })
  }

  returnWaitingTickets(){
    this.BlipServiceService.waitingTickets({queue: 'claim'}).subscribe((data)=>{
      this.waitingTickets = data.count
    })
  }
  getQueue() {
    this.BlipComponent.loadSpinner = true;
    this.BlipServiceService.getCollaborators({
      departament: 'claim',
    }).subscribe((collaborators) => {


      this.dataSource = collaborators || [];
    
      collaborators.length>0? this.emptyData = false: this.emptyData =true;
      this.BlipComponent.loadSpinner = false;

    });
  }

  openDialog(data) {
    this.dialog
      .open(NewCollaboratorsBlipComponent, {
        height:'430px',
        data: {
          addCollaborator: data.save,
          agentData: data.agentData,
          departament: 'claim',
          dataSource: this.dataSource,
          simultaneousTicket:this.form.get('simultaneous').value
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  }

  removeCollaborator(data) {
    this.dialog
      .open(RemoveCollaboratorComponent, {
        data: {
          agentData: data.agentData,
          departament: 'claim',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.getQueue();
      });
  } 
 
changeLimitTickets(operator: number, row) {
  row.maxTickets += operator;

  if (!this.ticketChangeSubjects[row.id]) {
    this.ticketChangeSubjects[row.id] = new Subject<any>();
    this.ticketChangeSubjects[row.id].pipe(
      debounceTime(1000), // 1 segundo de inatividade
      switchMap(({ row }) =>
        this.BlipServiceService.editCollaborator({ maxTickets: row.maxTickets, id: row.id })
      )
    ).subscribe(() => {
      this.getQueue();
    });
  }

  if (operator !== 0) {
    this.ticketChangeSubjects[row.id].next({ row });
  }}

  changeSimultaneousTicket(e) {
    this.form.get('simultaneous').setValue(e.target.value);
    window.localStorage.setItem('simultaneousTicketClaim', e.target.value);
    this.BlipServiceService.editSimultaneousTicket(this.idSimultaneousTicket, parseInt(e.target.value)).subscribe();
    this.dataSource.forEach((item) => {
      let itemTickets= parseInt(e.target.value);
      let maxTickets = item.maxTickets
      
      if (maxTickets> itemTickets) {
        this.BlipServiceService.editCollaborator({
          maxTickets:itemTickets,
          id: item.id,
        }).subscribe((data) => {
          this.getQueue();
        });
      }
    });
    this.getQueue();
  }

  onMenuClosed(){
    this.getQueue();
  };
}
