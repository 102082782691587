<ap-loading></ap-loading>

<div id="page-home">
  <div class="content">

    <main>

      <div class="title">
        <h1 class="intranet-title">INTRANET</h1>

        <div class="div-logo">
          <img src='../../../assets/images/logo.png' class="logo" />
        </div>
      </div>

      <h2 class="subtitle">Insurance agency that cares about people </h2>

      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" [style.fontSize.px]="18">

        <mat-form-field class="field">
          <mat-label>username</mat-label>
          <input matInput formControlName="fcUser" autocomplete="off">

          <mat-error *ngIf="isFieldInvalid('fcUser')">
            Please enter your user
          </mat-error>
        </mat-form-field>

        <mat-form-field class="field">
          <mat-label>password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="fcPwd">

          <mat-error *ngIf="isFieldInvalid('fcPwd')">
            Please enter your password
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" [disabled]="!formGroup.valid">Login</button>

        <div class="password-forgot">
          <a routerLink="/login/forgot-password/">Forgot your password?</a>
        </div>

      </form>

    </main>
  </div>
</div>
